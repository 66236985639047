import React from 'react';
import type { PopulatedSection } from 'root/apiTypes';
import { st, classes } from './Section.st.css';
import { classes as global } from 'root/components/Menus/Global.st.css';
import { Items } from 'root/components/Menus/Items/Items';
import { Text, TextTypography, Divider } from 'wix-ui-tpa/cssVars';
import { dataHooks } from 'root/dataHooks';
import { useSectionLogic } from './Section.logic';

export const Section = ({ section }: { section: PopulatedSection }) => {
  const { showSectionName, showSectionDescription, textAlignCss, shouldShowTopDivider, shouldShowBottomDivider } =
    useSectionLogic();
  return (
    <div className={classes.sectionContainer} data-hook={dataHooks.section.container} style={textAlignCss}>
      {shouldShowTopDivider && <Divider className={st(classes.divider, classes.dividerTop)} />}
      {showSectionName && (
        <Text
          className={st(global.fullWidth, classes.sectionTitle)}
          data-hook={dataHooks.section.name}
          typography={TextTypography.smallTitle}
        >
          {section.name}
        </Text>
      )}
      {showSectionDescription && (
        <Text className={st(global.fullWidth, classes.sectionDescription)} data-hook={dataHooks.section.description}>
          {section.description}
        </Text>
      )}
      {shouldShowBottomDivider && <Divider className={st(classes.divider, classes.dividerBottom)} />}
      <Items items={section.items ?? []} sectionId={section.id ?? ''} />
    </div>
  );
};
